import React from 'react';
import generator from 'generate-password';
import { useRouteMatch } from 'react-router-dom';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
export const CompanyContext = React.createContext();

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        //debug: true,
        interpolation: {
            escapeValue: false
        }
    });

export default ({ children, cfg }) => {
    const [config, setConfig] = React.useState(cfg);
    const [company, setCompany] = React.useState(null);
    const [forceUpdate, setForceUpdate] = React.useState(0);
    const [error, setError] = React.useState({
        Message: '',
        Dismissable: true,
        Code: ''
    });

    const route = useRouteMatch('/:org/:action?');
    const t = i18n.getFixedT(null, [company?.LanguageNameSpace, 'translation'].filter(p => p));

    const defaultContext = {
        config,
        company,
        error,
        setError,
        t
    };

    React.useEffect(() => {
        fetch(`Payment/GetConfig/${route?.params?.org || ''}?rev=${ generator.generate({ length: 10 }) }`, {
            headers: {
                'Accepts': 'application/json'
            }
        })
            .then(res => {
                return res.json();
            })
            .then(json => {
                setConfig(json);
            })
            .catch(err => {
                setError({
                    Message: "This site is currently experiencing technical difficulty",
                    Dismissable: false,
                    Code: '0x01'
                });
            });
    }, [route?.params?.org]);

    React.useEffect(() => {
        if (config?.Companies) {
            var org = route?.params?.org;
            var key = org?.toUpperCase()
            var match = config.Companies[key];
            if (!!!match) {
                setCompany(null);
            } else {
                match.Key = key;
                setCompany(match);
                if (match.LanguageNameSpace) {
                    i18n
                        .loadNamespaces(match.LanguageNameSpace)
                        .then(() => { setForceUpdate(match.LanguageNameSpace); });
                }
            }
        }
    }, [config, route]);

    return (
        <CompanyContext.Provider value={defaultContext} key={forceUpdate}>
            {children}
        </CompanyContext.Provider>
    );
};